:root {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
}

html, body {
  margin: 0;
  padding: 0;
  background: black;
  color: white;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.gonk-button {
  background: darkred;
  outline: none;
  border: none;
  color: white;
  font-size: 100px;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}
